.form-input {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 280px;
  height: 49px;
  background-color: rgba(#FFFFFF, 0.47);
  border: none;
  opacity: 0.85;
  border-radius: 5px;
  z-index: 0;

  &__icon {
    margin-left: 20px;
    margin-right: 10px;

    &.mail-icon {
      width: 13.63px;
    }

    &.lock-icon {
      width: 11px;
    }

    &.company-icon {
      width: 15.55px;
    }

    &.phone-icon {
      width: 13px;
    }
  }

  &__element {
    width: 100%;
    background-color: transparent;
    border: transparent;
    outline: none;

    font-family: 'Maison Neue Book';
    font-size: 16px;
    line-height: 1.0625em;
    color: #324659;

    @include breakpoint(medium down) {
      font-size: 14px;
    }
  }

  &__right-padding {
    width: 20px;
  }

  &__bottom-border {
    position: absolute;
    bottom: 0;
    margin: 0 5px;
    width: calc(100% - 10px);
    background-color: #FF9800;
    height: 1px;
  }

  /*
   * Modifiers
   */

  &--large {
    height: 55px;
  }

  &.form-input--light {
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.019),
      0px 8px 17px 0px rgba(0, 0, 0, 0.02);

    .form-input__element {
      color: #FFFFFF;

      &::placeholder {
        color: #FFFFFF;
      }
    }

    background-color: #FFFFFF33;
    border: #FFFFFF33;
  }

  &.form-input--dark-background {
    background-color: rgba(#EBEBEB, 0.47);
  }
}

.form-textarea {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 280px;
  height: 49px;
  background: #FFFFFF 0% 0% no-repeat;
  border: #FFFFFF;
  opacity: 0.85;
  border-radius: 5px;

  padding: 21px 43px;

  &__element {
    width: 100%;
    background-color: transparent;
    border: transparent;
    outline: none;
    resize: none;

    font-family: 'Maison Neue Book';
    font-size: 16px;
    line-height: 1.5em;
    color: #324659;

    @include breakpoint(medium down) {
      font-size: 14px;
    }
  }

  &__padding {
    width: 20px;
  }

  &.form-textarea--light {

    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.019),
      0px 8px 17px 0px rgba(0, 0, 0, 0.02);

    .form-textarea__element {
      color: #FFFFFF;

      &::placeholder {
        color: #FFFFFF;
      }
    }

    background-color: #FFFFFF33;
    border: #FFFFFF33;
  }

  &.form-textarea--dark-background {
    background-color: rgba(#EBEBEB, 0.47);
  }
}


.form-select {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 280px;
  height: 49px;
  background-color: rgba(#FFFFFF, 0.4);
  border: 1px solid #FF9800;
  border-radius: 5px;

  color: #324659;
  cursor: pointer;
  user-select: none;

  .form-select__element {
    position: absolute;
    opacity: 0;
    height: 1px;
    width: 1px;
    bottom: 0;
    left: 50%;
  }

  &__icon {
    margin-left: 10px;
    margin-right: 20px;

    &.select-icon {
      width: 9.25px;
    }
  }

  &.active {
    .form-select__icon {
      transform: rotate(180deg);
    }

    .form-select__option-list {
      display: block;
    }
  }

  &__text {
    width: 100%;
    padding-left: 21px;
    display: flex;
    align-items: center;

    font-family: 'Maison Neue Book';
    font-size: 16px;
    line-height: 1.0625em;
    color: #324659;
    z-index: 1;

    @include breakpoint(medium down) {
      font-size: 14px;
    }

  }

  .form-select__option-list {
    position: absolute;
    top: calc(100% + 1px);
    left: -1px;
    overflow: hidden;
    display: none;

    border: 1px solid #eeeeee;
    border-radius: 5px;

    width: 100%;

    .form-select__option {
      height: 49px;
      background-color: #FFFFFF;
      border-top: 1px solid #eeeeee;
      font-family: 'Maison Neue Book';
      display: flex;
      align-items: center;
      padding: 0 21px;

      &:first-child {
        border-top: none;
      }

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }

  /*
   * Modifiers
   */

  &--large {
    height: 55px;
  }

  &.form-select--light {
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.019),
      0px 8px 17px 0px rgba(0, 0, 0, 0.02);


    background-color: #FFFFFF33;
    border: #FFFFFF33;
  }

  &.form-select--dark-background {
    background-color: rgba(#EBEBEB, 0.47);
  }
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 30px 2px 2px;
  /*border: none; - if you want the border removed*/
}

.form-input.error .form-validity-label-content,
.form-checkbox.error .form-validity-label-content,
.form-select.error .form-validity-label-content,
.form-textarea.error .form-validity-label-content {
  visibility: visible;
  opacity: 1;
}

.form-validity-label {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  .form-validity-label-content {
    position: absolute;
    text-align: center;
    font-family: 'Maison Neue Light';
    color: #fff;
    font-size: 12px;
    line-height: 1.6em;
    background: black;
    left: 50%;
    transform: translate(-50%);
    padding: 4px 8px;
    border-radius: 5px;
    display: block;
    z-index: 1;
    visibility: hidden;
    opacity: 0;

    top: 5px;

    @include shadow-small;

    transition: 0.3s;

    &::after {
      content: " ";
      position: absolute;
      top: -2px;
      left: 50%;
      transform: translate(-50%);
      /* Out the top of the tooltip */
      margin-top: -8px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent black transparent;
    }

  }
}