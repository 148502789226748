.accept-conditions-checkbox a,
.accept-data-protection-checkbox a,
.accept-privacy-policy-checkbox a {
  font-family: 'Maison Neue Light';
  font-size: 12px;
  line-height: 16px;
  color: inherit;
  text-decoration: underline;
}

.accept-conditions-checkbox {
  z-index: 1;
}

.accept-data-protection-checkbox {
  z-index: 0;
}

.accept-privacy-policy-checkbox {
  z-index: 0;
}
