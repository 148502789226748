$screensizes: (small: (min-width: 481px),
  medium: (min-width: 751px),
  large: (min-width: 1101px),
  small down: (max-width: 480px),
  medium down: (max-width: 750px),
  large down: (max-width: 1100px)) !default;

/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin breakpoint($screensize) {

  // If the key exists in the map
  @if map-has-key($screensizes, $screensize) {

    // Prints a media query based on the value
    @media #{inspect(map-get($screensizes, $screensize))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$screensize}`. "
    +"Available screensizes are: #{map-keys($screensizes)}.";
  }
}

@mixin hide-scrollbars {
  // hide scroll bar
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

@mixin shadow-none {
  box-shadow: none;
}

@mixin shadow-small {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16),
    0px 2px 5px 0px rgba(0, 0, 0, 0.26);
}

@mixin shadow-medium {
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.19),
    0px 8px 17px 0px rgba(0, 0, 0, 0.2);
}

@mixin shadow-large {
  box-shadow: 0px 17px 50px rgba(0, 0, 0, 0.19),
    0px 12px 15px rgba(0, 0, 0, 0.24);
}