@import './dropdown-menu.scss';

:lang(en) .nav-row__links-container {
  margin-left: 75px;
}

.nav-row {
  padding-top: 26px;
  display: flex;

  @include breakpoint(medium down) {
    padding-top: 18px;
    justify-content: space-between;
  }

  &__logo-container {
    vertical-align: top;
    padding-top: 2px;
  }

  &__logo {}

  &__links-container {
    margin-left: 50px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-grow: 1;
  }

  &__links-left {
    display: flex;
    a:first-child {
      margin-left: 0;
    }
  }

  &__links-right {
    display: flex;
    a:last-child {
      margin-right: 0;
    }
  }

  &__links-button {
    margin-left: 13px;
    margin-right: 13px;

    box-shadow: 0px 6px 20px rgba(#000000, 0.19),
      0 8px 17px rgba(#000000, 0.2);

  }

  &__nav-item {
    margin-left: 13px;
    margin-right: 13px;
    position: relative;
    padding: 8px 0;
    cursor: pointer;

    &.no-margin-left {
      margin-left: 0;
    }

    &.no-margin-right {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      .nav-row__nav-item-arrow {
        opacity: 1;
        transition-delay: 0s;
      }
    }

    a {
      text-align: center;
      padding: 8px 0;
      vertical-align: middle;
    }

    .nav-row__nav-item-arrow-container {
      position: relative;
      cursor: pointer;
    }

    .nav-row__nav-item-arrow {
      transition: 0.2s;
      transition-delay: 0.1s;
      opacity: 0.65;
    }

    .nav-row__nav-item-language {
      text-transform: capitalize;
      vertical-align: middle;
    }

  }

  &__language-button {
    margin-right: 2px;
    width: 21px;
    height: 21px;
  }
}

.hamburger {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 10px;
  cursor: pointer;
  z-index: 4;

  &.active {
    .hamburger__line {
      background-color: #1464BF;
      opacity: 1;
    }

    .hamburger__line.top {
      transform: translateY(5px) rotate(45deg);
    }

    .hamburger__line.middle {
      opacity: 0;
    }

    .hamburger__line.bottom {

      transform: translateY(-5px) rotate(-45deg);
    }
  }

  .hamburger__line {
    transform-origin: center;
    position: relative;
    height: 2px;
    width: 18px;
    background-color: white;
    opacity: 0.54;

    margin: 1.5px 0;
    transition: 0.2s;
  }
}

.mobile-menu {
  position: absolute;
  left: 0;
  width: calc(100% - 22px);
  height: auto;
  background: white;
  margin: 11px;
  border-radius: 8px;
  transition: 0.2s ease;
  z-index: 4;

  padding-top: 23px;
  padding-bottom: 41px;

  box-shadow: 0 3px 6px rgba(#000000, 0.16);

  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);

  &.active {
    visibility: visible;
    opacity: 1 !important;
    transform: none;
  }

  .mobile-menu__item {
    padding-top: 20px;
    padding-bottom: 20px;

    &.active {
      .mobile-menu__list {
        max-height: 200px;
        margin-top: 24px;
      }

      .mobile-menu__header-arrow {
        transform: rotateX(180deg);
      }
    }

    .mobile-menu__header-container {
      display: flex;
      cursor: pointer;
    }

    .mobile-menu__header-arrow {
      margin-left: 31px;
      transition: 0.2s ease;
    }

    .mobile-menu__header {
      margin-left: 69px - 31px - 14px;
    }

    .mobile-menu__list {
      margin-left: 69px;
      max-height: 0;
      overflow: hidden;
      transition: 0.2s ease;

      a {
        display: block;
        margin-top: 24px;
        color: rgba(#324659, 0.75);

        &:first-child {
          margin-top: 0;
        }
      }
    }

  }

  .mobile-menu__line {
    height: 1px;
    width: 100%;
    background-color: #707070;
    opacity: 0.2;
  }

  .mobile-menu__links {
    display: flex;
    justify-content: space-evenly;
    margin-top: 35px;

    a {
      color: #42A5F5;
    }
  }

  .mobile-menu__start-button {
    width: calc(100% - 50px);
    margin: 0 25px;
    margin-top: 35px;
    font-size: 20px;
    font-family: 'Maison Neue Book';
  }

  .mobile-menu__language-container {
    display: flex;
    justify-content: center;
    margin-top: 33px;

    a {
      margin: 0 13px;
      font-size: 14px;

      img {
        margin-right: 4px;
      }
    }
  }

}

:lang(es) .mobile-menu__language-container .spanish {
  display: none;
}

:lang(en) .mobile-menu__language-container .english {
  display: none;
}

:lang(ca) .mobile-menu__language-container .catalan {
  display: none;
}

.floating-header {
  background-color: white;
  padding-bottom: 18px;
  position: fixed;
  width: 100%;
  z-index: 10;

  opacity: 0;
  pointer-events: none;

  transition: opacity 0.3s;

  @include shadow-small;

  .hamburger .hamburger__line {
    z-index: 3;
    background-color: #324659;
  }

  .mobile-menu {
    position: fixed;
  }

  &.disabled {
    display: none !important;
  }

  &.active {
    opacity: 1 !important;
    pointer-events: auto;

    .mobile-menu.active {
      opacity: 1 !important;
    }
  }

  .mobile-menu {
    opacity: 0;
  }
}
