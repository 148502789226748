.contact-form-section {
  background-color: rgba(#EBEBEB, 0.25);
  padding-top: 72px;
  padding-bottom: 76px;
  margin: auto;

  @include breakpoint(medium down) {
    padding-top: 75px;
    padding-bottom: 70px;
    width: initial;
    box-shadow: 0 2px 2px rgba(#000000, 0.16);
  }

  .section-title {}

  .section-subtitle {}

  .contact-form__content-container {
    width: 1092px;
    margin: auto;
    margin-top: 47px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    @include breakpoint(medium down) {
      width: initial;
      margin-top: 8px;
    }
  }

  .contact-form__form {
    margin-right: 84px;
    width: 648px;

    @include breakpoint(medium down) {
      width: 100%;
      margin-right: 0;
    }
  }

  .contact-form__content-divider-line {
    width: 1px;
    height: 775px;
    background-color: #CCCCCC;
    opacity: 0.25;
  }


}

.contact-form__content-contact-info {
  padding-top: 53px;
  padding-left: 62px;

  @include breakpoint(medium down) {
    padding: 50px 0;
  }

  &-item {
    margin: 24px 0;
  }

  &-title {
    font-family: 'Maison Neue Demi';
    font-size: 18px;
    line-height: 22px;
    color: #4A5B69;

    @include breakpoint(medium down) {
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.25px;
    }
  }

  &-text {
    font-family: 'Maison Neue Book';
    font-size: 14px;
    line-height: 22px;
    color: #4A5B69;

    @include breakpoint(medium down) {
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.25px;
    }

    a {
      font-family: 'Maison Neue Book';
      font-size: 16px;
      line-height: 1.6em;

      @include breakpoint(medium down) {
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0.25px;
      }
    }
  }
}