$track-background: transparent;
$track-background-full: #1565C092;
$track-height: 100%;

$thumb-height: 26px;
$thumb-width: 26px;
$thumb-border-radius: 14px;
$thumb-background:#1565C0;
$thumb-border-radius: 14px;

// General CSS
input[type=range].vertical-tab-range {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: $track-background-full;
  /* Otherwise white in Chrome */
  height: $track-height;
  border-radius: $track-height;
  margin: 12px 0;
  /* IE */
  writing-mode: bt-lr;
  /* WebKit */
  -webkit-appearance: slider-vertical;
}


input[type=range].vertical-tab-range:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range].vertical-tab-range::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

// Styling the Thumb

/* Special styling for WebKit/Blink */
input[type=range].vertical-tab-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: $thumb-height;
  width: $thumb-width;
  border-radius: $thumb-border-radius;
  background: $thumb-background;
  cursor: pointer;
  margin-top: -10px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

  @include shadow-small;
}

/* All the same stuff for Firefox */
input[type=range].vertical-tab-range::-moz-range-thumb {
  height: $thumb-height;
  width: $thumb-width;
  border: 0;
  border-radius: $thumb-border-radius;
  background: $thumb-background;
  cursor: pointer;
  @include shadow-small;
}

input[type=range].vertical-tab-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px #1565C059;
}

/* All the same stuff for IE */
input[type=range].vertical-tab-range::-ms-thumb {
  height: $thumb-height;
  width: $thumb-width;
  border-radius: $thumb-border-radius;
  background: $thumb-background;
  cursor: pointer;
  @include shadow-small;
}

/*
Style the track
*/
input[type=range].vertical-tab-range::-webkit-slider-runnable-track {
  width: 100%;
  height: $track-height;
  border-radius: $track-height;
  cursor: pointer;
  background: $track-background;
}

input[type=range].vertical-tab-range:focus::-webkit-slider-runnable-track {
  background: $track-background;
}

input[type=range].vertical-tab-range::-moz-range-track {
  width: 100%;
  height: $track-height;
  cursor: pointer;
  background: $track-background;
  border-radius: $track-height;
  border: 0;
}


input.vertical-tab-range::-moz-focus-inner,
input.vertical-tab-range::-moz-focus-outer {
  border: 0;
}

input[type=range].vertical-tab-range::-ms-track {
  width: 100%;
  height: $track-height;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range].vertical-tab-range::-ms-fill-lower {
  background: $track-background;
  border-radius: $track-height;
}

input[type=range].vertical-tab-range:focus::-ms-fill-lower {
  background: $track-background;
}

input[type=range].vertical-tab-range::-ms-fill-upper {
  background: $track-background;
  border-radius: $track-height;
}

input[type=range].vertical-tab-range:focus::-ms-fill-upper {
  background: $track-background;
}