img {
  object-fit: contain;
}

// Fixes the issue where lottie animations look blurry in some cases
.lottie-lazy svg {
  transform: unset !important;
}

.image-clouding-logo-header {
  width: 180px;
  height: 28.53px;
}

.tool-tip-icon {
  width: 15px;
  height: 15px;
}

.image-cogs {
  width: 95px;
  height: 83px;
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(medium down) {
    width: 78px;
    height: 69px;
    margin-top: 0;
  }
}

.image-arrow-down {
  width: 38px;
  height: 38px;
}

// Ventajas
.ventajas-header {
  height: 280px;

  @include breakpoint(medium down) {
    max-height: 154px;
  }
}

.image-facturacion-por-horas {
  width: auto;
  height: 260px;
  right: -21px;


  @include breakpoint(medium down) {
    width: 100%;
    height: 217px;
    right: unset;
  }
}

.old-image-facturacion-por-horas {
  width: 310px;
  height: 232px;

  @include breakpoint(medium down) {
    max-height: 192px;
  }
}

.image-alta-disponibilidad {
  width: 566px;
  height: auto;

  transform: translate(-100px, -50%) !important;

  @include breakpoint(medium down) {
    width: 100%;
    height: 152px;

    transform: translate(0, -50%) !important;
  }
}

.old-image-alta-disponibilidad {
  width: 554px;
  height: 226px;

  @include breakpoint(medium down) {
    max-height: 137px;
  }
}

.image-soporte-de-calidad {
  width: auto;
  height: 230px;
  right: -19px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 185px;
    right: unset;
  }
}

.old-image-soporte-de-calidad {
  width: 326px;
  height: 209px;

  @include breakpoint(medium down) {
    max-height: 169px;
  }
}

.image-data-center-barcelona {
  width: auto;
  height: 236px;
  left: -8px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 212px;
    left: unset;
  }
}

.old-image-data-center-barcelona {
  width: 288px;
  height: 226px;

  @include breakpoint(medium down) {
    max-height: 202px;
  }
}

.image-rendimiento-garantizado {
  width: auto;
  height: 250px;
  right: -15px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 198px;
    right: unset;
  }
}

.old-image-rendimiento-garantizado {
  width: 307px;
  height: 221px;

  @include breakpoint(medium down) {
    max-height: 190px;
  }
}

// Funcionalidades
.funcionalidades-header {
  width: 453px;
  height: 241px;

  @include breakpoint(medium down) {
    width: 198px;
    height: 123px;
  }
}

.image-crea-en-30-segundos {
  width: auto;
  height: 214px;
  right: -43px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 200px;
    right: unset;
  }
}

.old-image-crea-en-30-segundos {
  width: 252px;
  height: 206px;

  @include breakpoint(medium down) {
    max-height: 192px;
  }
}

.image-borra-y-crea-cuando-quieras {
  width: auto;
  height: 223px;
  left: -8px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 158px;
    left: unset;
  }
}

.old-image-borra-y-crea-cuando-quieras {
  width: 368px;
  height: 213px;

  @include breakpoint(medium down) {
    max-height: 148px;
  }
}

.image-archivado {
  width: auto;
  height: 315px;
  right: -15px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 202px;
    right: unset;
  }
}

.old-image-archivado {
  width: 467px;
  height: 303px;

  @include breakpoint(medium down) {
    max-height: 194px;
  }
}

.image-redimensionado {
  width: auto;
  height: 330px;
  left: -34px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 224px;
    left: unset;
  }
}

.old-image-redimensionado {
  width: 292px;
  height: 282px;

  @include breakpoint(medium down) {
    max-height: 194px;
  }
}

.image-snapshots-y-clonado {
  width: auto;
  height: 319px;
  left: -25px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 227px;
    left: unset;
  }
}

.old-image-snapshots-y-clonado {
  width: 423px;
  height: 297px;

  @include breakpoint(medium down) {
    max-height: 207px;
  }
}

.image-disaster-recovery {
  width: auto;
  height: 342px;
  right: -16px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 215px;
    right: unset;
  }
}

.old-image-disaster-recovery {
  width: 349px;
  height: 296px;

  @include breakpoint(medium down) {
    max-height: 186px;
  }
}

.image-backups {
  width: auto;
  height: 347px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 238px;
  }
}

.old-image-backups {
  width: 187px;
  height: 270px;

  @include breakpoint(medium down) {
    max-height: 188px;
  }
}

.image-red-privada {
  width: auto;
  height: 313px;
  right: -5px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 212px;
    right: unset;
  }
}

.old-image-red-privada {
  width: 493px;
  height: 281px;

  @include breakpoint(medium down) {
    max-height: 189px;
  }
}

.image-firewall {
  width: auto;
  height: 271px;
  left: -15px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 221px;
  }
}

.old-image-firewall {
  width: 373px;
  height: 221px;

  @include breakpoint(medium down) {
    max-height: 181px;
  }
}

.image-consola-de-emergencia {
  width: auto;
  height: 322px;
  right: -16px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 208px;
    right: unset;
  }
}

.old-image-consola-de-emergencia {
  width: 411px;
  height: 255px;

  @include breakpoint(medium down) {
    max-height: 168px;
  }
}

// Infraestructura
.infraestructura-header {
  width: 294px;
  height: 233px;

  @include breakpoint(medium down) {
    width: 166px;
    height: 124px;
  }
}

.image-infraestructura-alta-disponibilidad {
  width: auto;
  height: 314px;
  right: -6px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 215px;
    right: unset;
  }
}

.old-image-infraestructura-alta-disponibilidad {
  width: 292px;
  height: 277px;

  @include breakpoint(medium down) {
    max-height: 193px;
  }
}

.image-redundado {
  width: 566px;
  height: auto;

  transform: translate(-100px, -50%) !important;

  @include breakpoint(medium down) {
    width: 100%;
    height: 152px;

    transform: translate(0, -50%) !important;
  }
}

.old-image-redundado {
  width: 535px;
  height: 218px;

  @include breakpoint(medium down) {
    max-height: 136px;
  }
}

.image-triple-replica {
  width: auto;
  height: 212px;
  right: -4px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 148px;
    right: unset;
  }
}

.old-image-triple-replica {
  width: 354px;
  height: 202px;

  @include breakpoint(medium down) {
    max-height: 141px;
  }
}

.image-infraestructura-rendimiento-garantizado {
  width: auto;
  height: 250px;
  left: -15px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 198px;
    left: unset;
  }
}

.old-image-infraestructura-rendimiento-garantizado {
  width: 307px;
  height: 221px;

  @include breakpoint(medium down) {
    max-height: 175px;
  }
}

.image-anti-ddos {
  width: auto;
  height: 235px;
  left: -13px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 183px;
    left: unset;
  }
}

.old-image-anti-ddos {
  width: 344px;
  height: 215px;

  @include breakpoint(medium down) {
    max-height: 168px;
  }
}

.image-data-center-tier-iv {
  width: auto;
  height: 322px;
  right: -18px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 188px;
    right: unset;
  }
}

.old-image-data-center-tier-iv {
  width: 414px;
  height: 278px;

  @include breakpoint(medium down) {
    max-height: 164px;
  }
}

.image-ssd-nvme-enterprise {
  width: auto;
  height: 220px;
  left: -3px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 152px;
    left: unset;
  }
}

.old-image-ssd-nvme-enterprise {
  width: auto;
  height: 210px;
  left: -15px;

  @include breakpoint(medium down) {
    max-height: 144px;
  }
}

.image-kvm-hypervisor {
  width: auto;
  height: 239px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 203px;
  }
}

.old-image-kvm-hypervisor {
  width: 304px;
  height: 194px;

  @include breakpoint(medium down) {
    max-height: 164px;
  }
}

// API
.api-header {
  width: 278px;
  height: 187px;

  @include breakpoint(medium down) {
    width: 186px;
    height: 125px;
  }
}

.image-api {
  width: auto;
  height: 207px;

  @include breakpoint(medium down) {
    width: 100%;
    height: 133px;
  }
}

.old-image-api {
  width: 245px;
  height: 187px;

  @include breakpoint(medium down) {
    width: 157px;
    height: 121px;
  }
}

// Imagenes
.imagenes-header {
  width: 235px;
  height: 197px;

  @include breakpoint(medium down) {
    width: 172px;
    height: 144px;
  }
}

.ubuntu-image {
  width: 95px;
  height: 95px;

  @include breakpoint(medium down) {
    width: 44px;
    height: 45px;
  }
}

.centos-image {
  width: 95px;
  height: 95px;

  @include breakpoint(medium down) {
    width: 46px;
    height: 46px;
  }
}

.windows-image {
  width: 81px;
  height: 79px;

  @include breakpoint(medium down) {
    width: 36px;
    height: 36px;
  }
}

.ver-mas-image {
  width: 62px;
  height: 62px;

  @include breakpoint(medium down) {
    width: 34px;
    height: 34px;
  }
}

.image-soporte-2 {
  width: 91px;
  height: 75px;

  @include breakpoint(medium down) {
    width: 70px;
    height: 58px;
  }
}

.hestia-image {
  width: 100px;
  height: 100px;

  @include breakpoint(medium down) {
    width: 48px;
    height: 48px;
  }
}

.plesk-image {
  width: 111px;
  height: 49px;

  @include breakpoint(medium down) {
    width: 51px;
    height: 22px;
  }
}

.wordpress-image {
  width: 84px;
  height: 84px;

  @include breakpoint(medium down) {
    width: 40px;
    height: 40px;
  }
}

.prestashop-image {
  width: 84px;
  height: 84px;

  @include breakpoint(medium down) {
    width: 42px;
    height: 42px;
  }
}

.magento-image {
  width: 80px;
  height: 93px;

  @include breakpoint(medium down) {
    width: 38px;
    height: 45px;
  }
}

.ghost-image {
  width: 85px;
  height: 85px;

  @include breakpoint(medium down) {
    width: 42px;
    height: 42px;
  }
}

.odoo-image {
  width: 123px;
  height: 39px;

  @include breakpoint(medium down) {
    width: 42px;
    height: 42px;
  }
}

.docker-image {
  width: 94px;
  height: 68px;

  @include breakpoint(medium down) {
    width: 42px;
    height: 42px;
  }
}

// Nosotros
.image-nosotros-header {
  width: 350px;
  height: 210px;

  @include breakpoint(medium down) {
    width: 187px;
    height: 112px;
  }
}

// Soporte
.image-soporte-avanzado {
  width: 92px;
  height: 49px;

  @include breakpoint(medium down) {
    width: 65px;
    height: 33px;
  }
}

.image-soporte-clouding {
  width: 285px;

  @include breakpoint(medium down) {
    width: 138px;
  }
}

.image-cloud-pros {
  width: 244px;

  @include breakpoint(medium down) {
    width: 131px;
  }
}

//Soporte Pro
.image-clouding-pros-header {
  width: 338px;
  height: 230px;

  @include breakpoint(medium down) {
    width: 174px;
    height: 140px;
  }
}

// Sponsors
.image-acciona {
  height: 51px;
  width: 120px;

  @include breakpoint(medium down) {
    height: 32px;
    width: 74px;
  }
}

.image-audi {
  height: 53px;
  width: 85px;

  @include breakpoint(medium down) {
    height: 33px;
    width: 53px;
  }
}

.image-ethical {
  height: 37px;
  width: 98px;

  @include breakpoint(medium down) {
    height: 23px;
    width: 61px;
  }
}

.image-ferrovial {
  height: 27px;
  width: 125px;

  @include breakpoint(medium down) {
    height: 16px;
    width: 76px;
  }
}

.image-bluespace {
  height: 33px;
  width: 160px;

  @include breakpoint(medium down) {
    height: 21px;
    width: 100px;
  }
}

.image-repsol {
  height: 50px;
  width: 160px;

  @include breakpoint(medium down) {
    height: 32px;
    width: 90px;
  }
}

.image-salle {
  height: 41px;
  width: 187px;

  @include breakpoint(medium down) {
    height: auto;
    width: 155px;
  }
}

.image-q-hosp {
  height: 36px;
  width: 202px;

  @include breakpoint(medium down) {
    height: 22px;
    width: 126px;
  }
}

.image-sanitas {
  height: 47.5px;
  width: 131px;

  @include breakpoint(medium down) {
    height: 28px;
    width: 79px;
  }
}

.image-seat {
  height: 60px;
  width: 77px;

  @include breakpoint(medium down) {
    height: 37px;
    width: 48px;
  }
}

.image-cocheshots {
  height: 90px;
  width: auto;

  @include breakpoint(medium down) {
    height: 70px;
  }
}
