.faq {
  width: 678px;
  margin: auto;
  margin-top: 55px;
  padding: 0;

  @include breakpoint(medium down) {
    width: initial;
    margin-top: 46px;
  }
}

.faq__item {
  border-bottom: 1px solid #B7B7B7;

  @include breakpoint(medium down) {
    width: initial;
  }

  &.active {
    .faq__answer {
      max-height: 600px;
      margin-bottom: 25px;

      @include breakpoint(medium down) {
        max-height: 1500px;
      }
    }

    .faq__chevron {
      transform: rotate(90deg);
    }
  }

  .faq__question-container {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .faq__question {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    margin-right: 0;

    text-align: left;
    font-family: 'Maison Neue Demi';
    font-size: 16px;
    line-height: 1.2em;
    letter-spacing: 0;
    color: #324659;
    width: 100%;
    padding-right: 16px;

    @include breakpoint(medium down) {
      font-family: 'Maison Neue Demi';
      font-size: 16px;
      line-height: 1.25em;
      letter-spacing: 0;
    }
  }

  .faq__answer {
    text-align: left;
    font-family: 'Maison Neue Light';
    font-size: 14px;
    line-height: 1.8em;
    letter-spacing: 0;
    color: rgba(#324659, 0.75);
    padding: 0 16px;

    max-height: 0;
    margin-bottom: 0;
    transition: 0.3s ease;
    overflow: hidden;

    @include breakpoint(medium down) {
      font-family: 'Maison Neue Light';
      font-size: 14px;
      line-height: 1.8em;
      letter-spacing: 0;
    }
  }

  .faq__chevron {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    margin-right: 0;

    transition: 0.3s ease;

    @include breakpoint(medium down) {
      width: 10px;
      height: 16px;
      margin-right: 14px;
    }
  }
}