.section-title {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  color: #324659;

  line-height: 1em;

  @include breakpoint(medium down) {
    line-height: 41px;
  }

  &.section-title--white {
    color: #FFFFFF;
  }
}

.section-subtitle {
  margin-bottom: 0;
  margin-top: 25px;
  text-align: center;
  color: #324659;
  font-weight: 100;

  &.section-subtitle--white {
    color: #FFFFFF;
  }
}

.section-divider {
  margin: auto;
  padding-top: 80px;
  padding-bottom: 43px;
  @include shadow-small;

  @include breakpoint(medium down) {
    padding-top: 73px;
    padding-bottom: 70px;
    width: 100%;
  }

  &__title {
    color: #FFFFFF;

  }

  &__subtitle {
    margin-top: 16px;
    color: #FFFFFF;
  }
}