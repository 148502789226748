.contact-form__form {

  .contact-form__input-row {
    display: flex;
    justify-content: space-between;
    margin: 43px 0;

    @include breakpoint(medium down) {
      flex-direction: column;
      margin: 0;
    }

    .form-input,
    .form-select {
      width: 298px;

      @include breakpoint(medium down) {
        width: 100%;
        margin-top: 25px;
      }
    }
  }

  .contact-form__message {
    &.form-textarea {
      width: unset;
      height: 340px;

      @include breakpoint(medium down) {
        margin-top: 25px;
      }
    }
  }

  .accept-privacy-policy-checkbox {
    margin-top: 14px;

    @include breakpoint(medium down) {
      margin: 0 21px;
      margin-top: 14px;
    }
  }

  .contact-form__button {
    width: 202px;
    margin-top: 20px;

    @include breakpoint(medium down) {
      width: 100%;
    }
  }

  .contact-form__success-message {
    display: none;
    font-family: "Maison Neue Bold";
    font-size: 16px;
    color: $font-green;
    margin-top: 20px;
  }

  &.success .accept-conditions-checkbox,
  &.success .accept-data-protection-checkbox,
  &.success .accept-privacy-policy-checkbox,
  &.success .contact-form__button {
    display: none;
  }

  &.success .contact-form__success-message {
    display: block;
  }

  .contact-form__name {
    z-index: 5;
  }

  .contact-form__email {
    z-index: 4;
  }

  .contact-form__department {
    z-index: 3;
  }

  .contact-form__message {
    z-index: 2;
  }

}
