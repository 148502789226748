.header {
  @include breakpoint(medium down) {
    height: unset;
    padding-bottom: 70px;
    background: transparent linear-gradient(#1565C0 0%, #1E88E5 50%, #50CEFB 100%);
  }

  &__background {
    position: absolute;
    overflow: hidden;
    width: 100%;
    min-width: 1130px + 21px + 21px;
    height: 738px;
    left: 0;
    top: 0;
    z-index: -1;

    &-left {
      position: absolute;
      background-color: #0c4889;
      top: 0;
      left: -1450px;
      height: 542px;
      width: 50vw;
      box-shadow: (-50px 6px 20px rgba(0, 0, 0, .35));
      z-index: 1;
    }

    &-right {
      position: absolute;
      background-color: #38aae6;
      top: 0;
      right: -1450px;
      height: 542px;
      width: 50vw;
      box-shadow: (50px 6px 20px rgba(0, 0, 0, .35));
      z-index: 1;
    }

    &-image {
      position: absolute;
      transform: translate(-1500px, 0);
      left: 50%;
      filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.19)) drop-shadow(0px 8px 17px rgba(0, 0, 0, 0.2));
    }
  }

  &__title {
    color: $font-white;
    text-align: center;
    margin: 0;
    margin-top: 74px;

    @include breakpoint(medium down) {
      margin-top: 35px;
      line-height: 39px;
    }
  }

  &__subtitle {
    color: $font-white;
    text-align: center;
    margin: 0;
    margin-top: 14px;
  }

  &__video-sign-up-container {
    display: flex;
    justify-content: center;
    margin-top: 42px;

    @include breakpoint(medium down) {
      flex-direction: column;
      margin-top: 0;
    }
  }

  .window-buttons {
    display: flex;
  }

  .window-button {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-left: 3px;
    margin-top: 6px;
    margin-bottom: 6px;

    &--red {
      background-color: #ff536c;
    }

    &--green {
      background-color: #00d98c;
    }

    &--yellow {
      background-color: #ffd000;
    }
  }

  &__video-container {
    margin-top: 18px;
    margin-right: 50px;
    background: #EBEBEB 0% 0% no-repeat;
    border-radius: 10px;
    padding-top: 0;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    height: 404.5px;
    z-index: 0;

    @include shadow-large;

    @include breakpoint(medium down) {
      width: calc(100% - 16px);
      height: 100%;
    }
  }

  &__video-placeholder {
    position: absolute;
    z-index: 1;

    @include breakpoint(medium down) {
      width: calc(100% - 16px - 42px);
    }
  }

  &__video {
    position: relative;
    width: 518px;
    border-radius: 4px;
    z-index: 2;

    @include breakpoint(medium down) {
      width: 100%;
      height: 100%;
    }
  }

  &__sign-up {
    width: 412px;
    background-color: rgba(white, 0.95);
    border-radius: 10px;
    padding-bottom: 36px;

    @include shadow-large;

    @include breakpoint(medium down) {
      background-color: rgba(white, 0.9);
      padding: 0 22px;
      width: calc(100% - 44px);
      margin-top: 27px;
      padding-bottom: 47px;
    }
  }

  &__sign-up-title {
    margin-top: 43px;
    margin-bottom: 16px;
    text-align: center;
    line-height: 40px;
    padding: 0 16px;

    @include breakpoint(medium down) {
      padding: 0;
    }
  }

  &__sign-up-free-text-gift-icon {
    width: 26px;
    height: 24.5px;
    margin-bottom: 8px;

    @include breakpoint(medium down) {
      width: 18px;
      height: 19.66;
      margin-right: 4px;
    }
  }

  &__sign-up-free-text {
    font-family: 'Maison Neue Bold';
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0;
    color: $font-green;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;

    @include breakpoint(medium down) {
      font-size: 16px;
      line-height: 17px;
    }
  }

  &__email-input {
    margin-left: auto;
    margin-right: auto;
    width: 297px;
    z-index: 3;

    @include breakpoint(medium down) {
      width: 100%;
    }
  }

  &__password-input {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 297px;
    z-index: 2;

    @include breakpoint(medium down) {
      width: 100%;
    }
  }

  &__accept-conditions-checkbox {
    margin-top: 20px;
    margin-left: 58px;
    z-index: 1;

    @include breakpoint(medium down) {
      margin-left: 13px;
    }
  }

  &__accept-data-protection-checkbox {
    margin-top: 14px;
    margin-left: 58px;
    z-index: 0;

    @include breakpoint(medium down) {
      margin-left: 13px;
    }
  }

  &__start-button {
    width: 263px;
    display: block;
    margin-top: 18px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(medium down) {
      width: unset;
    }
  }
}
