.form-vertical-tab__global {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0 45px;

  @include breakpoint(medium down) {
    padding: 0;
    flex-direction: column;
  }
}

.form-vertical-tab__cards-container {
  position: relative;

  .form-vertical-tab__card-placeholder {
    @include breakpoint(medium down) {
      display: none;
    }
  }

}

.form-vertical-tab__content {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 581px;
  visibility: hidden;
  opacity: 0;
  height: 0;

  transition: 0.3s;


  @include breakpoint(medium down) {
    flex-direction: column;
    width: initial;
    position: initial;

    transition: none;

    &.active {}
  }

  &.active {
    visibility: visible;
    opacity: 1;
    height: auto;

    transition: 0.3s 0.15s;
  }
}

.form-vertical-tab__card-placeholder-container {
  position: absolute;
  top: 0;
  left: 0;

  &.move {
    top: 15px;
    left: -22px;
    transition: .3s;
  }
}

$original-card-top: 40px;

.form-vertical-tab__card-content {
  &.move {
    top: -10px;
    opacity: 0;

    transition: .3s;
  }

}

.form-vertical-tab__content-card {
  position: absolute;
  width: 581px;
  height: 165px;
  border: 1px solid #1E88E5;
  border-radius: 10px;
  padding: 80px 50px 100px 40px;
  background: white;
  top: $original-card-top;
  left: 0;

  @include breakpoint(medium down) {
    position: initial;
    width: initial;
    height: initial;

    margin-top: 43px;
    padding: 24px 34px 32px 24px;
  }

  @include shadow-medium;

  $card-translate-right: 22px;
  $card-translate-up: 15px;

  &.number1 {
    left: $card-translate-right;
    top: $original-card-top - $card-translate-up;
    border: 1px solid #1E88E550;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, .25);
    text-indent: $card-translate-right;
    border-spacing: $card-translate-up;
    z-index: -1;
  }

  &.number2 {
    left: $card-translate-right*2;
    top: $original-card-top - $card-translate-up*2;
    border: 1px solid #1E88E540;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, .20);
    z-index: -2;
  }

  &.number3 {
    left: $card-translate-right*3;
    top: $original-card-top - $card-translate-up*3;
    border: 1px solid #1E88E530;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, .15);
    z-index: -3;
  }

  &.number4 {
    left: $card-translate-right*4;
    top: $original-card-top - $card-translate-up*4;
    border: 1px solid #1E88E520;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, .10);
    z-index: -4;
  }

  &.number5 {
    left: $card-translate-right*5;
    top: $original-card-top - $card-translate-up*5;
    border: 1px solid #1E88E510;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, .05);
    z-index: -5;
  }

  &-icon {
    width: 97px;

    @include breakpoint(medium down) {
      width: initial;
      height: 66px;
    }
  }

  &-icon-container {
    @include breakpoint(medium down) {
      display: flex;
      align-items: center;
    }
  }

  &-title {
    text-align: left;
    font-family: 'Maison Neue Demi';
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0;
    color: #4A5B6A;

    @include breakpoint(medium down) {
      margin-left: 8px;
    }
  }

  &-text {
    margin-top: 10px;
    margin-left: 22px;

    @include breakpoint(medium down) {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  &-description {
    margin-top: 25px;
    text-align: left;
    font-family: 'Maison Neue Book';
    font-size: 16px;
    line-height: 1.6em;

    letter-spacing: 0;
    color: #4A5B6A;

    @include breakpoint(medium down) {
      margin-top: 15px;
    }
  }

  &-link {
    margin-top: 32px;
  }
}

.form-vertical-tab__container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px;

  @include breakpoint(medium down) {
    padding: 0;
    padding-left: 35px - $content-padding;
    justify-content: flex-start
  }
}

.form-vertical-tab__left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
}

.form-vertical-tab__line {
  position: relative;
  background-color: #4A5B6A;
  width: 3px;
  height: calc(100% - 47px);
  margin-top: 19px;

  @include breakpoint(medium down) {
    height: calc(100% - 15px);
    margin-top: 0;
  }

  .form-vertical-tab__circle {
    position: absolute;
    background: #FFFFFF 0% 0% no-repeat;
    width: 13px;
    height: 13px;
    border: 3px solid #42A5F5;
    border-radius: 50%;
    top: 0;

    left: -8px;

    -webkit-transition: top .2s ease;
    transition: top .2s ease;

    @include breakpoint(medium down) {
      width: 32px;
      height: 32px;
      left: -17px;

      @include shadow-small;
    }
  }
}


.form-vertical-tab {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 3px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: 'Maison Neue Book';
  font-size: 18px;
  line-height: 61px;

  @include breakpoint(medium down) {
    padding-left: 20px + 31px;
    font-family: 'Maison Neue Medium';
    font-size: 18px;
    line-height: 43px;
    letter-spacing: 0.0025em;
  }

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;


    &+span {
      -webkit-transition: .2s;
      transition: .2s;
    }


    &:checked+span {
      font-family: 'Maison Neue Demi';
      color: #42A5F5;
    }
  }

}

.form-vertical-tab__range {
  transform: rotate(90deg);

  $track-background: #4A5B6A;
  $track-height: 3px;

  $thumb-size: 19px;
  $thumb-size-mobile: 32px;
  $thumb-border-color: #42A5F5;
  $thumb-background: #ffffff;

  // General CSS
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  background: $track-background;
  /* Otherwise white in Chrome */
  height: $track-height;
  position: absolute;
  z-index: 2;

  &:focus {
    outline: none;
    /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  // Styling the Thumb

  /* Special styling for WebKit/Blink */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: $thumb-size;
    width: $thumb-size;
    border-radius: $thumb-size;
    background: $thumb-background;
    border: 3px solid $thumb-border-color;
    cursor: pointer;
    margin-top: (-1 * $thumb-size / 2) + ($track-height / 2);
    /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

    @include breakpoint(medium down) {
      height: $thumb-size-mobile;
      width: $thumb-size-mobile;
      border-radius: $thumb-size-mobile;

      margin-top: (-1 * $thumb-size-mobile / 2) + ($track-height / 2);

      @include shadow-small;
      // Need to rotate to get shadow correctly oriented
      transform: rotate(-90deg);
    }
  }

  /* All the same stuff for Firefox */
  &::-moz-range-thumb {
    height: $thumb-size;
    width: $thumb-size;
    border: 0;
    border-radius: $thumb-size;
    background: $thumb-background;
    border: 3px solid $thumb-border-color;
    cursor: pointer;

    @include breakpoint(medium down) {
      height: $thumb-size-mobile;
      width: $thumb-size-mobile;
      border-radius: $thumb-size-mobile;

      @include shadow-small;
      // Need to rotate to get shadow correctly oriented
      transform: rotate(-90deg);
    }
  }

  /* All the same stuff for IE */
  &::-ms-thumb {
    height: $thumb-size;
    width: $thumb-size;
    border-radius: $thumb-size;
    background: $thumb-background;
    border: 3px solid $thumb-border-color;
    cursor: pointer;

    @include breakpoint(medium down) {
      height: $thumb-size-mobile;
      width: $thumb-size-mobile;
      border-radius: $thumb-size-mobile;

      @include shadow-small;
      // Need to rotate to get shadow correctly oriented
      transform: rotate(-90deg);
    }
  }

  /*
Style the track
*/
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: $track-height;
    border-radius: $track-height;
    cursor: pointer;
    background: $track-background;
  }

  &:focus::-webkit-slider-runnable-track {
    background: $track-background;
  }

  &::-moz-range-track {
    width: 100%;
    height: $track-height;
    cursor: pointer;
    background: $track-background;
    border-radius: $track-height;
    border: 0;
  }


  &::-moz-focus-inner,
  &::-moz-focus-outer {
    border: 0;
  }

  &::-ms-track {
    width: 100%;
    height: $track-height;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: $track-background;
    border-radius: $track-height;
  }

  &:focus::-ms-fill-lower {
    background: $track-background;
  }

  &::-ms-fill-upper {
    background: $track-background;
    border-radius: $track-height;
  }

  &:focus::-ms-fill-upper {
    background: $track-background;
  }

}
