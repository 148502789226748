@import "../../scss/defaults.scss";

// Custom Elements
@import "../../scss/form";
@import "../../scss/button";
@import "../../scss/checkbox";
@import "../../scss/radio-button";
@import "../../scss/vertical-tab";
@import "../../scss/vertical-tab-range";
@import "../../scss/form-circle-tabs";
@import "../../scss/range";
@import "../../scss/toggle";
@import "../../scss/section";
@import "../../scss/nav-row.scss";
@import "../../scss/footer";
@import "../../scss/register-section";
@import "../../scss/contact-form-section";
@import "../../scss/contact-form-form";
@import "../../scss/accept-terms-conditions.scss";
@import "../../scss/images";
@import "../../scss/button-link.scss";
@import "../../scss/tool-tip.scss";

@import "./partials/faqs/faqs";

@import "../../scss/header";

.home-section1 {

  &__title {
    margin-top: 100px;

    @include breakpoint(medium down) {
      margin-top: 75px;
      line-height: 41px;
    }
  }

  &__subtitle {}

  &__configurations {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 77px;
    margin-left: auto;
    margin-right: auto;
    width: 1130px + 15px + 15px;
    padding: 0 6px;
    height: 290px;

    @include breakpoint(medium down) {
      flex-direction: column;
      width: unset;
      height: 479px;
      margin-top: 32px;
      padding: 0;
    }
  }

  &__configuration {
    width: 221px;
    height: 282px;
    border: 1px solid #1565C0;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    transition: .2s;

    @include shadow-small;

    &:hover {
      .home-section1__configuration-header {
        &-amount {
          color: white;
        }

        &-per-period {
          color: white;
        }

        &-max-per-month {
          color: white;
        }
      }
    }

    @include breakpoint(medium) {
      &:hover {
        width: 243px;
        height: 311px;

        @include shadow-large;

        .home-section1__configuration-line {
          display: none;
        }

        .home-section1__configuration-header {
          background-color: #1565C0;

          @include shadow-small;
        }
      }
    }

    @include breakpoint(medium down) {
      flex-direction: row-reverse;
      width: calc(100% - 40px);
      height: 72px;
      margin: 9px 0;

      &:hover {
        width: calc(100% - 20px);
        height: 91px;
        border: none;

        background: linear-gradient(-10deg, #50CEFB 0%, #1E88E5 40%, #1565C0 100%);

        .home-section1__configuration-spec {
          color: white;
        }

        .home-section1__configuration-line {
          background-color: white;
          height: calc(100% - 16px);
        }
      }
    }
  }

  &__configuration-header {
    text-align: center;
    padding-top: 22px;
    transition: 0.2s;
    flex: 0 0 30px;

    @include breakpoint(medium down) {
      height: unset;
      flex: 0 0 130px;
      padding-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-hour {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }

    &-amount {
      font-family: 'Maison Neue Demi';
      font-size: 34px;
      line-height: 34px;
      color: #1565C0;
      transition: .2s;

      @include breakpoint(medium down) {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.01em;
      }
    }

    &-per-period {
      font-family: 'Maison Neue Book';
      font-size: 18px;
      line-height: 34px;
      color: #1565C0;
      transition: .2s;

      @include breakpoint(medium down) {
        font-family: 'Maison Neue Medium';
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.050em;
        margin-left: 2px;
      }
    }

    &-max-per-month {
      font-family: 'Maison Neue Book';
      font-size: 16px;
      line-height: 34px;
      color: #58636E;
      transition: .2s;
      margin-bottom: 12px;

      @include breakpoint(medium down) {
        font-size: 11px;
        line-height: 13px;
        margin-top: 4px;
        margin-bottom: 0;
      }
    }
  }

  &__configuration-line {
    background-color: #1565C0;
    flex: 0 0 2px;
    width: calc(100% - 24px);
    margin: 0 12px;
    transition: .2s;

    @include breakpoint(medium down) {
      flex: 0 0 1px;
      height: 100%;
      margin: auto;
    }
  }

  &__configuration-specs {
    flex: auto;
    transition: .2s;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 14px 0;

    @include breakpoint(medium down) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 8px;
      column-gap: 16px;
    }
  }

  &__configuration-spec {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    text-align: center;
    color: #58636E;
    transition: .2s;

    @include breakpoint(medium down) {
      font-family: 'Maison Neue Light';
      font-size: 12px;
      letter-spacing: 0.004em;
      line-height: 14px;
      color: #324659;

      &:last-child {
        flex: 0 0 100%;
      }
    }

    &-vcore {
      display: block;
    }

    &-vcore-mobile {
      display: none;
    }

    @include breakpoint(medium down) {
      &-vcore {
        display: none;
      }

      &-vcore-mobile {
        display: block;
      }
    }

    &--bold {
      font-weight: bold;
      @include breakpoint(medium down) {
        font-family: 'Maison Neue Demi';
        font-weight: normal;
      }
    }
  }

  &__configuration-text {
    font-family: 'Maison Neue Demi';
    font-size: 23px;
    line-height: 32px;
    color: #ffffff;
    transition: .2s;
    text-align: center;
    margin-top: 8px;

    &--mobile {
      display: none;
    }

    @include breakpoint(medium down) {
      display: none;
      font-family: 'Maison Neue Medium';
      font-size: 16px;
      letter-spacing: 0.04em;
      line-height: 19px;
      margin-top: 0;
      flex: auto;

      &--mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__configuration-arrow-down {
    display: flex;
    justify-content: center;

    @include breakpoint(medium down) {
      display: none;
    }
  }

  /*
    * Modifier
    */
  &__configuration--configure {
    background-color: #1565C0;

    .home-section1__configuration-header {
      @include breakpoint(medium) {
        flex: 0 0 85px;
      }
    }

    .home-section1__configuration-line {
      background-color: white;
    }

    .home-section1__configuration-specs {
      row-gap: 8px;
    }

    &:hover {
      .home-section1__configuration-header {
        // prevent a shadow from appearing
        @include shadow-none;
      }

      .home-section1__configuration-line {
        // prevent the line from disappearing
        display: block;
      }
    }
  }
}

.home-section2 {
  &__title {
    margin-top: 155px;

    @include breakpoint(medium down) {
      margin-top: 67px;
      line-height: 41px;
    }
  }

  &__subtitle {}

  &__toggle {
    text-align: center;
    margin-top: 60px;

    @include breakpoint(medium down) {
      margin-top: 41px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 1000px;
    margin: auto;
    margin-top: 80px;

    @include breakpoint(medium down) {
      margin-top: 0;
      width: unset;
      flex-direction: column;
    }
  }

  &__group {
    width: 50%;

    @include breakpoint(medium down) {
      width: unset;
    }

    &-title {
      font-family: 'Maison Neue Demi';
      text-align: left;
      margin-top: 70px;

      @include breakpoint(medium down) {
        font-size: 16px;
        line-height: 19px;
        margin-top: 50px;
      }
    }


    &-license {
      display: flex;
      color: $font-dark-grey;

      &-linux {
        @include breakpoint(medium down) {
          width: 50%;
        }
      }

      &-windows {
        margin-left: 38px;
        width: 50%;

        @include breakpoint(medium down) {
          margin-left: 0;
          width: 50%;
        }
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 16px;

      &-title {
        font-family: 'Maison Neue Demi';
        color: $font-dark-grey;
        margin: 0;

        &-extra {
          font-family: 'Maison Neue Light';
        }

        @include breakpoint(medium down) {
          font-size: 16px;
          line-height: 19px;
        }

        .tooltip {
          display: inline-flex;
          align-items: center;
        }
      }

      &-display {
        display: flex;
        justify-content: center;
        flex-direction: column;

        &-container {

          &-inner {
            display: flex;
            justify-content: center;
            flex-direction: column;

            border: 1px solid #1565C0;
            border-radius: 10px;
            height: 36px;
            padding: 0 10px;

            &-text {
              font-family: "Maison Neue Book";
              line-height: 27px;
              color: #1565C0;
            }
          }
        }
      }
    }

    &-ram {
      margin-top: 32px;
      color: #324659;


      &-group {
        display: flex;

        &-2 {
          @include breakpoint(medium down) {
            width: 50%;
          }
        }

        &-4 {
          margin-left: 90px;

          @include breakpoint(medium down) {
            margin-left: 0;
            width: 50%;
          }
        }
      }
    }

    &-procesador {
      margin-top: 32px;
    }

    &-ssd {
      margin-top: 32px;
    }

    &-button {
      margin-top: 100px;
      margin-left: 8px;

      @include breakpoint(medium down) {
        margin: auto;
        margin-top: 35px;
      }
    }

    &-additional {
      text-align: left;
      font-family: 'Maison Neue Book';
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0;
      color: #58636E;
      margin-top: 32px;
      margin-left: 8px;

      @include breakpoint(medium down) {
        margin: auto;
        margin-top: 20px;
        text-align: center;
      }

      a {
        font-family: 'Maison Neue Book';
        color: #1565C0;
        text-decoration: underline;
        font-size: 12px;
      }
    }
  }

  &__group2 {
    width: 385px;
    height: 632px;
    border: 1px solid #1565C0;
    border-radius: 10px;
    overflow: hidden;
    @include shadow-small;

    @include breakpoint(medium down) {
      width: unset;
      height: unset;
      margin-top: 48px;
      padding-bottom: 10px;
    }

    &-price-table {
      margin: auto;
      width: 100%;
      height: 100%;
      border-collapse: collapse;
      text-align: right;

      @include breakpoint(medium down) {
        font-size: 16px;
        line-height: 1.2em;
        letter-spacing: 0.005em;
      }

      thead tr {
        height: 74px;
        background-color: #1565C0;
        color: #FFFFFF;
        font-family: 'Maison Neue Light';
        font-size: 21px;
        line-height: 25px;

        @include shadow-small;

        @include breakpoint(medium down) {
          height: 48px;
        }
      }

      tbody tr {
        height: 74px;
        color: #58636E;
        font-family: 'Maison Neue Light';
        font-size: 24px;
        line-height: 29px;

        @include breakpoint(medium down) {
          font-family: 'Maison Neue Light';
          font-size: 16px;
          line-height: 1.2em;
          height: 48px;
        }
      }

      td,
      th {
        width: 50%;
      }

      .label {
        font-family: 'Maison Neue Demi';
        padding-left: 19px;
        text-align: left;
      }

      .column1 {
        padding-right: 35px;

        @include breakpoint(medium down) {
          padding-right: 21px;
        }
      }

      .column2 {
        padding-right: 70px;

        @include breakpoint(medium down) {
          padding-right: 38px;
        }
      }

      @include breakpoint(medium down) {
        .home-section2__group2-price-licensia {
          height: 77px;

          td {
            padding-top: 30px;
          }
        }
      }

      td.total {
        text-align: center;
        padding: 0;
        color: #1565C0;

        @include breakpoint(medium down) {
          text-align: right;
          padding-right: 38px;
        }

        .total-amount {
          font-family: 'Maison Neue Demi';
          font-size: 46px;

          @include breakpoint(medium down) {
            font-family: 'Maison Neue Light';
            font-size: 28px;
            line-height: 38px;
          }
        }

        .total-per-period {
          font-family: 'Maison Neue Demi';
          font-size: 23px;

          @include breakpoint(medium down) {
            font-size: 12px;
          }
        }
      }

    }

  }

}

.home-section3 {
  margin-top: 140px;

  @include breakpoint(medium down) {
    margin-top: 35px;
  }

  &__section-divider {
    height: 335px - 43px;
    background: transparent linear-gradient(270deg, #0B4182 0%, #1E88E5 53%, #50CEFB 100%) 0% 0% no-repeat;
    min-width: 1130px + 21px + 21px;
    padding-top: 0;

    @include breakpoint(medium down) {
      background: linear-gradient(-10deg, #50CEFB 0%, #1E88E5 40%, #1565C0 100%);
      height: unset;
      padding-bottom: 66px;
      min-width: unset;
    }

    .section-divider__title {
      padding-top: 46px;

      @include breakpoint(medium down) {
        padding-top: 75px;
      }
    }
  }


  &__cards {
    margin-top: 56px;
    display: flex;
    justify-content: space-between;

    @include breakpoint(medium down) {
      margin-top: 30px;
      flex-direction: column;
    }
  }

  &__card {
    width: 350px;
    border-radius: 10px;
    background: #FFFFFF 0% 0% no-repeat;
    padding-top: 48px;
    padding-bottom: 45px;
    @include shadow-medium;

    @include breakpoint(medium down) {
      width: unset;
      height: unset;

      margin-top: 27px;
      margin-left: 35px - 21px;
      margin-right: 35px - 21px;

      padding-top: 40px;
      padding-bottom: 28px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-container {
      display: flex;
      height: 58px;
      padding: 0 41px;

      @include breakpoint(medium down) {
        padding: 0 32px;
      }
    }

    #fact-horas-icon {
      margin-left: -14px;
      margin-right: 25px - 14px;
      margin-top: -10px;
      width: 80px;
      height: 80px;
    }

    #alta-disponibilidad-icon {
      margin-left: -14px;
      margin-right: 25px - 14px;
      margin-top: -10px;
      width: 80px;
      height: 80px;
    }

    #soporte-cerca-icon {
      margin-left: -14px;
      margin-right: 25px - 14px;
      margin-top: -10px;
      width: 80px;
      height: 80px;
    }


    &-icon {
      margin-right: 25px;

      .alta-dispo-icon {
        width: 54.45px;
        height: 54.45px;
      }

      .suporte-icon {
        width: 66.17px;
        height: 54.36px;
      }
    }

    &-title {
      font-family: 'Maison Neue Demi';
      font-size: 21px;
      color: #4A5B6A;

      @include breakpoint(medium down) {
        font-family: 'Maison Neue Medium';
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.0015em;
      }

    }

    &-description {
      margin-top: 42px;
      text-align: left;
      font-family: 'Maison Neue Book';
      font-size: 16px;
      line-height: 1.6em;
      letter-spacing: 0;
      color: #4A5B6A;
      padding: 0 41px;

      @include breakpoint(medium down) {
        margin-top: 19px;
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0.025em;

        padding: 0 32px;
      }
    }

    &-link {
      margin-top: 42px;
      padding: 0 41px;

      @include breakpoint(medium down) {
        margin-top: 20px;
        padding: 0 32px;
      }
    }
  }
}

.section4 {
  padding-top: 192px + 157px;

  @include breakpoint(medium down) {
    padding-top: 75px;
    padding-bottom: 8px;
  }

  &__title {}

  &__subtitle {
    margin-bottom: 125px;

    @include breakpoint(medium down) {
      margin-bottom: 50px;
    }
  }

  .form-vertical-tab__range {
    margin-top: -3px;
    width: 338px;

    @include breakpoint(medium down) {
      margin-top: -3px;
      width: 260px;
    }
  }

  .form-vertical-tab__cards-container {
    margin-left: 173px;
  }
}

.section5 {
  &__title {
    margin-top: 150px;
    margin-bottom: 0;

    @include breakpoint(medium down) {
      margin-top: 75px;
    }
  }

  &__subtitle {
    margin-top: 22px;
  }

  &__form-circle-tabs {
    margin-top: 85px;

    @include breakpoint(medium down) {
      margin-top: 36px;
    }
  }

  &__form-circle-tabs-selected {}
}

.section6 {
  margin-top: 100px;

  @include breakpoint(medium down) {
    margin-top: 75px;
  }
}

.section7 {
  &__title {
    margin-top: 128px;

    @include breakpoint(medium down) {
      margin-top: 75px;
    }
  }

  &__subtitle {
    margin-top: 28px;

    @include breakpoint(medium down) {
      margin-top: 20px;
    }
  }
}

.testimonial-carousel {
  display: flex;
  justify-content: center;
  width: 879px;
  margin: auto;
  margin-top: 55px;

  @include breakpoint(medium down) {
    display: initial;
    width: inherit;
  }

  &__content-window {}

  $bottom-padding: 65px;
  $top-padding: 30px;
  $side-padding: 90px;

  &__slide {
    width: 739px - $side-padding - $side-padding;
    background: #FFFFFF 0% 0% no-repeat;
    border-radius: 10px;
    padding: $top-padding $side-padding $bottom-padding $side-padding;

    @include shadow-medium;

    @include breakpoint(medium down) {
      width: initial;
      height: initial;
      min-height: 290px;
      padding: 20px 34px 40px 34px;
      margin-top: 20px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // The height for the current testimonials. Might change if we add others
      min-height: 445px;

      @include breakpoint(medium down) {
        min-height: 290px;
      }
    }

    &-logo {
      display: flex;
      min-height: 194px;

      @include breakpoint(medium down) {
        min-height: 70px;
      }

      img {
        margin: auto;
        // max-height: 90px;
        // max-width: 200px;

        @include breakpoint(medium down) {
          // max-height: 70px;
          // max-width: 180px;
        }
      }
    }

    &-quote {
      flex: auto;
      text-align: center;
      font-family: "Maison Neue Light Italic";
      font-size: 27px;
      line-height: 40px;
      letter-spacing: 0;
      color: #58636E;

      min-height: 80px;

      margin-top: 20px;


      @include breakpoint(medium down) {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 16px;
        line-height: 1.5em;
        margin-top: 0;
      }
    }

    &-name {
      text-align: center;
      font-family: "Maison Neue Demi";
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0;
      color: #58636E;
      margin-top: 20px;

      @include breakpoint(medium down) {
        font-family: "Maison Neue Medium";
        font-size: 16px;
        line-height: 1.5em;
        margin-top: 20px;
      }
    }

    &-position {
      text-align: center;
      font-family: "Maison Neue Book";
      font-size: 16px;
      line-height: 1.5em;
      color: #58636E;

      @include breakpoint(medium down) {
        font-family: "Maison Neue Light";
        font-size: 12px;
        line-height: 24px;
      }
    }
  }

  &__left-icon {
    display: flex;

    img {
      margin: auto;
      cursor: pointer;
      opacity: 35%;
    }
  }

  &__right-icon {
    display: flex;

    img {
      margin: auto;
      cursor: pointer;
    }
  }

  &__indicators {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 87px;

    @include breakpoint(medium down) {
      margin-top: 35px;
    }
  }

  &__indicator {
    width: 8px;
    height: 8px;
    border: 2px solid #A6A6A6;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;

    &--selected {
      background: #A6A6A6 0% 0% no-repeat;
      border-radius: 50%;
    }
  }
}

.section8 {
  margin-top: 47px;
  margin-bottom: 125px;

  @include breakpoint(medium down) {
    margin-top: 70px;
    margin-bottom: 75px;
  }

  &__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-top: 60px;
    margin-bottom: 60px;

    @include breakpoint(medium down) {
      margin-top: 40px;
      margin-bottom: 40px;
    }


    &>div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 0 0 20%;

      @include breakpoint(medium down) {

        &:first-child img {
          margin-left: 0;
        }

        &:last-child img {
          margin-right: 0;
        }
      }

      img {
        margin: auto;

        @include breakpoint(medium down) {
          margin: 0 auto;
        }
      }

    }
  }

  &__button-container {
    display: flex;
    margin-top: 75px;

    @include breakpoint(medium down) {
      margin-top: 54px;
    }

    a {
      margin: auto;
    }
  }

  &__button {
    width: 238px;
    margin: auto;
  }
}

.section9 {
  background: #EBEBEB 0% 0% no-repeat;
  padding-top: 128px;
  min-width: 1130px + 21px + 21px;
  margin: auto;
  padding-bottom: 77px + $footer-height;


  @include breakpoint(medium down) {
    height: initial;
    width: initial;
    min-width: initial;
    padding-top: 56px;
    padding-bottom: 75px;
  }

  &__title {
    color: #324659;
  }

  &__subtitle {
    margin-top: 23px;
    color: #324659;
  }

  &__link-container {
    margin-top: 47px;

    @include breakpoint(medium down) {
      margin-top: 36px;
    }
  }

  &__link {
    margin-left: 218px;

    @include breakpoint(medium down) {
      margin-left: 0;
    }
  }
}
