$button-light-blue: #42A5F5;
$button-light-blue-hover: #5EBAFF;
$button-light-blue-pressed: #5EBAFF;
$button-light-blue-pressed-font-color: #1565C0;

$button-orange: #FF9800;
$button-orange-hover: #F5AD42;
$button-orange-pressed: #F5AD42;
$button-orange-pressed-font-color: #B56E03;

$button-dark-blue: #1565C0;
$button-dark-blue-hover: #297DDD;
$button-dark-blue-pressed: #297DDD;
$button-dark-blue-pressed-font-color: #324659;

$button-arrow-button: #FF9800;
$button-arrow-button-hover: #F5AD42;

.button {
  font-family: 'Maison Neue Demi';
  font-size: 16px;
  text-decoration: none;
  background-color: white;
  border: white;
  color: #333333;
  border-radius: 100px;
  padding: unset;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  cursor: pointer;
  height: 48px;

  box-shadow: 0 2px 10px rgba(#000, 0.16),
    0 2px 5px rgba(#000, 0.26);

  transition: 0.1s;

  &--with-arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 35px;
    padding-right: 8px;

    background-color: $button-dark-blue;
    border: $button-dark-blue;
    color: #FFFFFF;

    &:hover {

      .button__arrow {
        background-color: $button-arrow-button-hover;
      }
    }

    &:active {
      .button__arrow {
        background-image: url('../../public/img/ic_arrow_forward-dark.svg');
      }
    }
  }

  &__arrow {
    height: 34px;
    width: 34px;
    display: flex;
    background-color: $button-arrow-button;
    border-radius: 34px;
    margin-left: 32px;

    transition: 0.2s;

    background-image: url('../../public/img/ic_arrow_forward-light.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  /*
   * Modifiers
   */
  &--large {
    height: 55px;
    font-size: 17px;
    letter-spacing: 0;
  }

  &--small {
    font-family: 'Maison Neue Demi';
    font-size: 16px;
    height: 41px;
    letter-spacing: 0;
  }

  &--light-blue {
    background-color: $button-light-blue;
    border: $button-light-blue;
    color: #FFFFFF;

    &:hover {
      background-color: $button-light-blue-hover;
      border: $button-light-blue-hover;
    }

    &:active {
      background-color: $button-light-blue-pressed;
      border: $button-light-blue-pressed;
      color: $button-light-blue-pressed-font-color;
    }
  }

  &--orange {
    background-color: $button-orange;
    border: $button-orange;
    color: #FFFFFF;

    &:hover {
      background-color: $button-orange-hover;
      border: $button-orange-hover;
    }

    &:active {
      background-color: $button-orange-pressed;
      border: $button-orange-pressed;
      color: $button-orange-pressed-font-color;
    }
  }

  &--dark-blue {
    background-color: $button-dark-blue;
    border: $button-dark-blue;
    color: #FFFFFF;

    &:hover {
      background-color: $button-dark-blue-hover;
      border: $button-dark-blue-hover;
    }

    &:active {
      background-color: $button-dark-blue-pressed;
      border: $button-dark-blue-pressed;
      color: $button-dark-blue-pressed-font-color;
    }
  }
}