.form-circle-tabs {
  overflow-x: scroll;
  overflow-y: hidden;
  width: calc(100% + 42px);
  margin-left: 0 - $content-padding;

  @include hide-scrollbars;

  .form-circle-tabs__container {
    position: relative;
    width: 922px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
    padding-top: 10px;

    @include breakpoint(medium down) {
      padding-left: $content-padding;
      padding-right: $content-padding;
    }
  }

  .form-circle-tabs__line {
    position: absolute;
    width: 820px;
    height: 1px;
    background-color: #42A5F5;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 67px;
    z-index: -1;
  }

  input {
    display: none;
  }

  $form-circle-tabs-transition: .2s;

  input:checked~.form-circle-tab__circle {
    transition: $form-circle-tabs-transition;
    -webkit-transition: $form-circle-tabs-transition;
    border: 1px solid #42A5F5;

    @include shadow-medium;
  }

  input:checked~.form-circle-tab__label {
    transition: $form-circle-tabs-transition;
    -webkit-transition: $form-circle-tabs-transition;
    color: #42A5F5;
  }

  .form-circle-tab {
    cursor: pointer;

    &__circle {
      width: 111px;
      height: 111px;
      border-radius: 50%;
      background: #FFFFFF 0% 0% no-repeat;
      border: 1px solid #FFFFFF;

      @include shadow-small;
    }

    &__icon {
      height: 100%;
    }

    &__hardware-icon {
      width: 53px;
    }

    &__software-icon {
      width: 60px;
    }

    &__red-icon {
      width: 50px;
    }

    &__imagenes-icon {
      width: 68px;
    }

    &__data-center-icon {
      width: 52px;
    }

    &__label {
      text-align: center;
      font-family: 'Maison Neue Medium';
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02px;
      color: #324659;
      margin-top: 23px;
    }
  }

}

.form-circle-tabs__selected {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 942px;
  margin-top: 113px;
  margin-left: auto;
  margin-right: auto;

  // transition: 0.3s;
  // opacity: 0;

  @include breakpoint(medium down) {
    width: initial;
    height: initial;
    flex-direction: column;
    margin-top: 35px;
  }

  // &.active {
  //   display: flex;
  // }

  // &.animate {
  //   opacity: 1;
  // }

  &-title {
    text-align: left;
    font-family: 'Maison Neue Demi';
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.68px;
    color: #4A5B6A;
    margin-top: 8px;

    @include breakpoint(medium down) {
      font-family: 'Maison Neue Medium';
      font-size: 20px;
      line-height: 30px;
    }
  }

  &-description {
    text-align: left;
    font-family: 'Maison Neue Book';
    font-size: 16px;
    line-height: 1.6em;
    letter-spacing: 0;
    color: #324659;
    margin-top: 28px;
    width: 352px;

    @include breakpoint(medium down) {
      font-size: 16px;
      line-height: 1.5em;
      margin-top: 20px;
      width: initial;
    }
  }

  &-link {
    margin-top: 30px;
  }

  &-section-icon {
    @include breakpoint(medium down) {
      display: flex;
      justify-content: center;
    }

    img {
      height: 57px;
      min-height: 57px;
      max-width: 100%;
    }
  }

  &-section-title {
    text-align: left;
    font-family: 'Maison Neue Demi';
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #4A5B6A;
    margin-top: 19px;

    @include breakpoint(medium down) {
      text-align: center;
      margin-top: 16px;
    }
  }

  &-section-description {
    margin-top: 19px;
    text-align: left;
    font-family: 'Maison Neue Book';
    font-size: 16px;
    line-height: 1.6em;
    letter-spacing: 0;
    color: #4A5B6A;

    @include breakpoint(medium down) {
      text-align: center;
      margin-top: 16px;
    }
  }

  &-section1 {
    grid-area: section1;
    margin-right: 25px;
  }

  &-section2 {
    grid-area: section2;
    margin-right: 25px;
    margin-top: 36px;
  }

  &-section3 {
    grid-area: section3;
    margin-left: 25px;
  }

  &-section4 {
    grid-area: section4;
    margin-left: 25px;
    margin-top: 36px;
  }

  &-grid {
    width: 420px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "section1 section3"
      "section2 section4";

    @include breakpoint(medium down) {
      width: initial;
      margin-top: 46px;
    }
  }
}
