$form-checkbox-text-color: #7C858D;
$form-checkbox-border-color: #7C858D;
$form-checkbox-checked-color: #7C858D;

.form-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  font-family: 'Maison Neue Light';
  font-size: 12px;
  line-height: 16px;
  color: $form-checkbox-text-color;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1px;
    width: 1px;
    bottom: 0;
    left: 0.5rem;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #FFFFFF;
    border: 1px solid $form-checkbox-checked-color;
    border-radius: 3px;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked~.checkmark {
    background-color: #FFFFFF;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 3px;
    top: 7px;
    width: 2px;
    height: 2px;
    transform: rotate(-45deg);
    background: $font-green;
    box-shadow:
      0px 0px 0 $font-green,
      0px 2px 0 $font-green,
      0px 3px 0 $font-green,
      0px 4px 0 $font-green,

      1px 4px 0 $font-green,
      2px 4px 0 $font-green,
      3px 4px 0 $font-green,
      4px 4px 0 $font-green,
      5px 4px 0 $font-green,
      6px 4px 0 $font-green,
      7px 4px 0 $font-green,
      8px 4px 0 $font-green;
  }


  /*
   * Modifiers
   */

  &--light {
    color: white;

    input:checked~.checkmark {
      background-color: transparent;
    }

    $white: #FFFFFF;

    & .checkmark {
      background-color: transparent;
      border-color: white;
    }
  }
}
