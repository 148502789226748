$track-background: transparent;
$track-background-full: #1565C092;
$track-height: 6px;

$thumb-height: 26px;
$thumb-width: 26px;
$thumb-border-radius: 14px;
$thumb-background:#1565C0;
$thumb-border-radius: 14px;

// General CSS
input[type=range].range {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: $track-background-full;
  /* Otherwise white in Chrome */
  height: $track-height;
  border-radius: $track-height;
  margin: 12px 0;
}


input[type=range].range:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range].range::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

// Styling the Thumb

/* Special styling for WebKit/Blink */
input[type=range].range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;

  margin-top: -10px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

  height: $thumb-height;
  width: $thumb-width;
  border: 0;
  border-radius: $thumb-border-radius;
  background: $thumb-background;
  cursor: pointer;

  @include shadow-small;
}

/* All the same stuff for Firefox */
input[type=range].range::-moz-range-thumb {
  height: $thumb-height;
  width: $thumb-width;
  border: 0;
  border-radius: $thumb-border-radius;
  background: $thumb-background;
  cursor: pointer;

  @include shadow-small;
}


/* All the same stuff for IE */
input[type=range].range::-ms-thumb {
  height: $thumb-height;
  width: $thumb-width;
  border: 0;
  border-radius: $thumb-border-radius;
  background: $thumb-background;
  cursor: pointer;

  @include shadow-small;
}

input[type=range].range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px #1565C059;
}

input[type=range].range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 10px #1565C059;
}

input[type=range].range:focus::-ms-thumb {
  box-shadow: 0 0 0 10px #1565C059;
}

/*
Style the track
*/
input[type=range].range::-webkit-slider-runnable-track {
  width: 100%;
  height: $track-height;
  border-radius: $track-height;
  cursor: pointer;
  background: $track-background;
}

input[type=range].range:focus::-webkit-slider-runnable-track {
  background: $track-background;
}

input[type=range].range::-moz-range-track {
  width: 100%;
  height: $track-height;
  cursor: pointer;
  background: $track-background;
  border-radius: $track-height;
  border: 0;
}


input.range::-moz-focus-inner,
input.range::-moz-focus-outer {
  border: 0;
}

input[type=range].range::-ms-track {
  width: 100%;
  height: $track-height;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range].range::-ms-fill-lower {
  background: $track-background;
  border-radius: $track-height;
}

input[type=range].range:focus::-ms-fill-lower {
  background: $track-background;
}

input[type=range].range::-ms-fill-upper {
  background: $track-background;
  border-radius: $track-height;
}

input[type=range].range:focus::-ms-fill-upper {
  background: $track-background;
}

.range__container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input[type=range].range {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.range__button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;

  @include shadow-small;
}

.range__minus-icon {
  height: 4px;
  width: 12px;
  background-color: $font-green;
}

.range__plus-icon1 {
  height: 4px;
  width: 12px;
  background-color: $font-green;
}

.range__plus-icon2 {
  position: absolute;
  top: 5px;
  height: 12px;
  width: 4px;
  background-color: $font-green;
}