/*
 * FONT IMPORTS
 */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');


@font-face {
  font-family: 'Maison Neue Mono Italic';
  src:
    url('/font/MaisonNeue-MonoItalic.woff') format('woff'),
    url('/font/MaisonNeue-MonoItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Mono';
  src:
    url('/font/MaisonNeue-Mono.woff') format('woff'),
    url('/font/MaisonNeue-Mono.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Medium Italic';
  src:
    url('/font/MaisonNeue-MediumItalic.woff') format('woff'),
    url('/font/MaisonNeue-MediumItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Medium';
  src:
    url('/font/MaisonNeue-Medium.woff') format('woff'),
    url('/font/MaisonNeue-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Light Italic';
  src:
    url('/font/MaisonNeue-LightItalic.woff') format('woff'),
    url('/font/MaisonNeue-LightItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Light';
  src:
    url('/font/MaisonNeue-Light.woff') format('woff'),
    url('/font/MaisonNeue-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Demi Italic';
  src:
    url('/font/MaisonNeue-DemiItalic.woff') format('woff'),
    url('/font/MaisonNeue-DemiItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Demi';
  src:
    url('/font/MaisonNeue-Demi.woff') format('woff'),
    url('/font/MaisonNeue-Demi.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Book';
  src:
    url('/font/MaisonNeue-Book.woff') format('woff'),
    url('/font/MaisonNeue-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Bold Italic';
  src:
    url('/font/MaisonNeue-BoldItalic.woff') format('woff'),
    url('/font/MaisonNeue-BoldItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue Bold';
  src:
    url('/font/MaisonNeue-Bold.woff') format('woff'),
    url('/font/MaisonNeue-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


/*
 * VARIABLES
 */
$font-lightdark: #58636E;
$font-dark: #333333;
$font-blue: #42A5F5;
$font-dark-grey: #324659;
$font-white: #F4F9FF;
$font-green:#12D854;

.px-global-nav {
  font-family: 'Maison Neue Demi';
  font-size: 16px;
  line-height: 1.1875em;
  letter-spacing: 0.02px;
  color: $font-white;
}

h1 {
  font-family: 'Maison Neue Demi';
  font-size: 39px;
  line-height: 70px;
  letter-spacing: 0;
  color: $font-dark-grey;
  text-align: center;
  font-weight: 400;

  @include breakpoint(medium down) {
    font-size: 34px;
    letter-spacing: -0.25px;
  }
}

@mixin px-h2 {
  font-family: 'Maison Neue Demi';
  font-size: 27px;
  line-height: 58px;
  letter-spacing: 0;
  color: $font-dark;
  font-weight: 400;

  @include breakpoint(medium down) {
    font-size: 24px;
    letter-spacing: 0.025em;
    line-height: 39px;
  }
}

h2 {
  @include px-h2;
}

h3 {
  font-family: 'Maison Neue Light';
  font-size: 21px;
  line-height: 1.6em;
  letter-spacing: 0;
  color: $font-dark-grey;
  text-align: center;
  font-weight: 400;

  @include breakpoint(medium down) {
    font-size: 20px;
  }
}

h4 {
  font-family: 'Maison Neue Book';
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
  color: $font-dark;
  font-weight: 400;
}

h5,
.px-h5 {
  font-family: 'Maison Neue Book';
  font-size: 18px;
  line-height: 1.5em;
  letter-spacing: 0;
  color: $font-dark;
  font-weight: 400;

  @include breakpoint(medium down) {
    font-size: 16px;
  }
}

h6 {
  font-family: 'Maison Neue Demi Italic';
  font-size: 16px;
  line-height: 1.0625em;
  letter-spacing: 0.28px;
  color: $font-blue;
  font-weight: 400;
}

.font-body {
  font-family: 'Maison Neue Book';
  font-size: 12px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: $font-dark;
}

.font-quote {
  font-family: 'Maison Neue Light Italic';
  font-size: 27px;
  line-height: 32px;
  letter-spacing: 0;
  color: $font-dark;
}

a {
  font-family: 'Maison Neue Demi';
  font-size: 16px;
  line-height: 1.0625em;
  letter-spacing: 0;
  color: $font-blue;
}

.testimonial1 {
  font-family: 'Maison Neue Demi';
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0;
  color: $font-lightdark;
  text-transform: uppercase;
}

.testimonial2 {
  font-family: 'Maison Neue Book';
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0;
  color: $font-lightdark;
  text-transform: uppercase;
}

.micro-copy {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  line-height: 1.125em;
  letter-spacing: 0.28px;
  color: $font-dark;
}

@include breakpoint(medium down) {

  .px-global-nav {
    font-family: 'Maison Neue Demi';
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0;
    color: $font-dark;
  }

  .px-global-nav-dropdown {
    font-family: 'Maison Neue Book';
    font-size: 16px;
    line-height: 1.1875em;
    letter-spacing: 0;
    color: $font-dark;
  }

  h1 {
    font-family: 'Maison Neue Demi';
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 0;
    color: $font-dark;
  }

  h2 {
    font-family: 'Maison Neue Demi';
    font-size: 27px;
    line-height: 58px;
    letter-spacing: 0;
    color: $font-dark;
  }

  h3 {
    font-family: 'Maison Neue Light';
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    color: $font-dark;
  }

  h4 {
    font-family: 'Maison Neue Book';
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: $font-dark;
  }

  h5 {
    font-family: 'Maison Neue Book';
    font-size: 16px;
    line-height: 1.0625em;
    letter-spacing: 0;
    color: $font-dark;
  }

  h6 {
    font-family: 'Maison Neue Demi Italic';
    font-size: 16px;
    line-height: 1.0625em;
    letter-spacing: 0.28px;
    color: $font-blue;
  }

  .font-body1 {
    font-family: 'Maison Neue Book';
    font-size: 12px;
    line-height: 27px;
    letter-spacing: 0.15px;
    color: $font-dark;
  }

  .font-body2 {
    font-family: 'Maison Neue Medium';
    font-size: 12px;
    line-height: 27px;
    letter-spacing: 0.15px;
    color: $font-dark;
  }

  .font-quote {
    font-family: 'Maison Neue Medium';
    font-size: 12px;
    line-height: 27px;
    letter-spacing: 0.18px;
    color: $font-dark;
  }

  a {
    font-family: 'Maison Neue Demi';
    font-size: 16px;
    line-height: 1.875em;
    letter-spacing: 0;
    color: $font-blue;
  }

  .form {
    font-family: 'Maison Neue Medium';
    font-size: 16px;
    line-height: 1.875em;
    letter-spacing: 0;
    color: $font-dark-grey;
  }

  .testimonial1,
  .toggle1 {
    font-family: 'Maison Neue Demi';
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0;
    color: $font-lightdark;
    text-transform: uppercase;
  }

  .testimonial2,
  .toggle2 {
    font-family: 'Maison Neue Book';
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0;
    color: $font-lightdark;
    text-transform: uppercase;
  }

  .micro-copy {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    line-height: 1.125em;
    letter-spacing: 0.28px;
    color: $font-dark;
  }

}

/*
 * COLORS
 */
.color-lightdark {
  color: $font-lightdark;
}

.color-dark {
  color: $font-dark;
}

.color-blue {
  color: $font-blue;
}

.color-form {
  color: $font-dark-grey;
}

.color-white {
  color: $font-white;
}

/*
 * FONT ATTRIBUTES
 */
.all-caps {
  text-transform: uppercase;
}

.letter-spacing-15 {
  letter-spacing: 0.15px;
}

.letter-spacing-40 {
  letter-spacing: 0.40px;
}

/*
 * FONT SIZES
 */
.size-39px {
  font-size: 39px;
}

.size-34px {
  font-size: 34px;
}

.size-27px {
  font-size: 27px;
}

.size-24px {
  font-size: 24px;
}

.size-21px {
  font-size: 21px;
}

.size-20px {
  font-size: 20px;
}

.size-18px {
  font-size: 18px;
}

.size-16px {
  font-size: 16px;
}

.size-12px {
  font-size: 12px;
}


/*
 * FONT FAMILIES
 */

.source-sans-pro {
  font-family: 'Source Sans Pro', sans-serif;
}

.maison-neue-mono {
  font-family: 'Maison Neue Mono';
}

.maison-neue-mono-italic {
  font-family: 'Maison Neue Mono Italic';
}

.maison-neue-medium {
  font-family: 'Maison Neue Medium';
}

.maison-neue-medium-italic {
  font-family: 'Maison Neue Medium Italic';
}

.maison-neue-light {
  font-family: 'Maison Neue Light';
}

.maison-neue-light-italic {
  font-family: 'Maison Neue Light Italic';
}

.maison-neue-demi {
  font-family: 'Maison Neue Demi';
}

.maison-neue-demi-italic {
  font-family: 'Maison Neue Demi Italic';
}

.maison-neue-book {
  font-family: 'Maison Neue Book';
}

.maison-neue-bold {
  font-family: 'Maison Neue Bold';
}

.maison-neue-bold-italic {
  font-family: 'Maison Neue Bold Italic';
}