$distance: 137px;
$distance-mobile: 120px;
$transition: .2s;

.switch {
  position: relative;
  display: inline-block;
  width: 287px;
  height: 53px;

  & input {
    display: none;


    &:checked+.slider:after {
      width: 150px;
      -webkit-transform: translateX($distance);
      -ms-transform: translateX($distance);
      transform: translateX($distance);
      will-change: transform;
    }

    &:checked~.text .left {
      -webkit-transition: $transition;
      transition: $transition;
      color: black;
      will-change: transform;
      will-change: color;
    }

    &:checked~.text .right {
      -webkit-transition: $transition;
      transition: $transition;
      color: white;
      will-change: transform;
      will-change: color;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    border-radius: 34px;

    box-shadow: 0 2px 10px rgba(#000000, 0.16),
      0 2px 5px rgba(#000000, 0.26);

  }

  .slider:after {
    position: absolute;
    content: "";
    height: 53px;
    width: 141px;
    left: 0;
    bottom: 0;
    background-color: #1565C0;
    -webkit-transition: $transition;
    transition: $transition;
    border-radius: 34px;
    will-change: transform;
  }

  .text {
    cursor: pointer;
    display: flex;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(0, -7px);
    justify-content: space-around;
    flex-direction: row;
    z-index: 1;
    text-transform: uppercase;

    font-family: 'Maison Neue Light';
    font-size: 12px;
    line-height: 14px;

    .left {
      color: white;
      will-change: color;
    }

    .right {
      color: black;
      will-change: color;
    }

    .mobile {
      display: none;
    }
  }

  .noselect {
    /* iOS Safari */
    -webkit-touch-callout: none;
    /* Safari */
    -webkit-user-select: none;
    /* Konqueror HTML */
    -khtml-user-select: none;
    /* Old versions of Firefox */
    -moz-user-select: none;
    /* Internet Explorer/Edge */
    -ms-user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    user-select: none;
  }

  @include breakpoint(medium down) {
    width: 251px;
    height: 35px;

    & input {
      &:checked+.slider:after {
        width: 132px;
        transform: translateX($distance-mobile);
      }
    }

    .slider {
      background-color: #EDF2F6;
    }

    .slider:after {
      height: 35px;
      width: 121px;
    }

    .text {
      font-size: 14px;

      .desktop {
        display: none;
      }

      .mobile {
        display: inline;
      }
    }
  }
}