@import "./constants.scss";

ul {
  list-style-type: none;
}

.footer {
  height: $footer-height;
  margin-top: 0 - $footer-height;

  @include breakpoint(medium down) {
    background-color: #1E2F37;
    height: initial;
    padding: 0 21px;
    padding-top: 48px;
    padding-bottom: 60px;
    margin-top: 0;
  }

  &__background {
    position: absolute;
    overflow: hidden;
    width: 100%;
    min-width: $content-width;
    height: $footer-height;
    left: 0;

    &-left {
      position: absolute;
      left: 2px;
      bottom: 0;
      height: $footer-height - 222px;
      width: calc(50vw - 1280px);
      background: linear-gradient(0deg, #1E323B 0%, #17272f 100%);
      z-index: 1;
    }

    &-right {
      position: absolute;
      right: 2px;
      bottom: 0;
      height: $footer-height;
      width: calc(50vw - 1280px);
      background-color: #000;
      background: linear-gradient(0deg, #1E323B 0%, #132026 100%);
      z-index: 1;
    }

    &-image {
      position: absolute;
      transform: translate(-1280px, 0);
      left: 50%;
      height: $footer-height + 5px;
      width: 2560px;
    }
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;

    @include breakpoint(medium down) {
      position: initial;
    }
  }

  &__main {
    display: flex;
    flex-direction: row;
    padding-top: 168px;
    margin-bottom: 43px;

    @include breakpoint(medium down) {
      display: initial;
    }
  }

  &__column1 {
    margin-right: 249px;

    @include breakpoint(medium down) {
      margin-right: 0;
    }
  }


  &__social-media-icons-container {
    margin-top: 37px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 183px;

    @include breakpoint(medium down) {
      margin-left: initial;
      margin-right: initial;
      margin-top: 16px;
    }
  }

  $social-media-icon-size: 32px;

  &__social-media-facebook-icon {
    height: $social-media-icon-size;
    width: $social-media-icon-size;
  }

  &__social-media-x-icon {
    height: $social-media-icon-size;
    width: $social-media-icon-size;
  }

  &__social-media-instagram-icon {
    height: $social-media-icon-size;
    width: $social-media-icon-size;
  }

  &__social-media-linkedin-icon {
    height: $social-media-icon-size;
    width: $social-media-icon-size;
  }

  &__links-container {
    display: flex;
    color: #8D9EA6;

    @include breakpoint(medium down) {
      display: initial;
    }
  }

  &__links-list {
    opacity: 1;
    padding: 0;
    margin: 0;
    margin-right: 45px;
    flex: 1 0 165px;

    @include breakpoint(medium down) {
      font-family: 'Maison Neue Light';
      line-height: 30px;
      margin-top: 32px;
      flex: auto;
    }

    a {
      font-family: 'Maison Neue Book';
      font-size: 16px;
      line-height: 2.375em;
      text-align: left;
      letter-spacing: 0;
      color: #8D9EA6;

      @include breakpoint(medium down) {
        font-family: 'Maison Neue Light';
        line-height: 1.875em;
      }
    }
  }

  &__links-list-header>a {
    font-family: 'Maison Neue Demi';
    font-size: 18px;
    line-height: 38px;
    color: #FFFFFF;

    @include breakpoint(medium down) {
      font-size: 16px;
      line-height: 1.5em;
    }
  }

  &__logo {
    width: 183px;
    height: 141px;

    @include breakpoint(medium down) {
      width: 132px;
      height: 101px;
    }
  }

  &__certificado-9001-27001 {
    margin-top: 12px;

    &-img {
      width: 172px;
      height: 80px;
    }

    @include breakpoint(medium down) {
      margin: 0;
      margin-top: 20px;
    }
  }

  &__hr {
    background: #848181 0% 0% no-repeat;
    opacity: 0.4;
  }

  &__copyright-terms-container {
    display: flex;
    flex-direction: row;
    margin-top: 38px;
  }

  &__copyright {
    color: #FFFFFF;
    text-align: left;
    font-family: 'Maison Neue Demi';
    font-size: 16px;
    line-height: 1.875em;
    letter-spacing: 0;
    width: 432px;

    @include breakpoint(medium down) {
      font-family: 'Maison Neue Book';
      font-size: 11px;
      line-height: 20px;
      color: rgba(#FFFFFF, 0.75);
      margin-top: 32px;
      width: 100%;
    }
  }

  &__cif-and-address {
    @include breakpoint(medium down) {
      font-family: 'Maison Neue Book';
      font-size: 11px;
      line-height: 20px;
      color: rgba(#FFFFFF, 0.75);
      text-align: left;
      letter-spacing: 0;
      width: 100%;
    }
  }

  &__terms {
    color: #FFFFFF;
    text-align: left;
    font-family: 'Maison Neue Light';
    font-size: 16px;
    line-height: 1.875em;
    letter-spacing: 0;

    a {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
    }
  }

}
