/* Tooltip container */
.tooltip {
  position: relative;
  cursor: pointer;
  display: inline;
}

/* Tooltip text */
.tooltip .tooltiptext {

  &,
  a {
    font-family: 'Maison Neue Light';
    color: #fff;
    font-size: 12px;
    line-height: 1.6em;
    font-weight: 400;
  }

  a {
    text-decoration: underline;
  }

  visibility: hidden;
  width: 240px;
  background-color: $font-blue;
  text-align: center;
  padding: 8px;
  border-radius: 6px;

  @include shadow-small;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext {
  top: 32px;
  left: -182px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: -2px;
  right: 58px;
  /* Out the top of the tooltip */
  margin-top: -16px;
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent $font-blue transparent;

}

.tooltip .tooltiptext {
  opacity: 0;
  transition: opacity .2s 0s,
    visibility 0s .2s;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
  transition: opacity .2s .5s,
    visibility 0s 0s;
}
