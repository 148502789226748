$content-padding: 21px;
$content-width: 1130px+$content-padding+$content-padding;

body {
  min-width: $content-width;

  @include breakpoint(medium down) {
    min-width: initial;
  }

}

.content-container {
  width: 1130px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 21px;

  @include breakpoint(medium down) {
    width: unset;
  }
}

.flex {
  display: flex;
}

.flex.center {
  justify-content: center;
}

.flex.align-items-center {
  align-items: center;
}

.flex.space-around {
  justify-content: space-around;
}

.flex.space-between {
  justify-content: space-between;
}

.flex.space-evenly {
  justify-content: space-evenly;
}

.flex.row {
  flex-direction: row;
}

.flex.column {
  flex-direction: column;
}

.show-on-desktop {
  @include breakpoint(medium down) {
    display: none !important;
  }
}

.show-on-mobile {
  @include breakpoint(medium) {
    display: none !important;
  }
}

.hide-on-mobile {
  @include breakpoint(medium down) {
    display: none !important;
  }
}

.scrollable {
  overflow: auto;
}

.grecaptcha-badge {
  visibility: hidden;
}