$transition-duration: .2s;
$transition-delay: 0s;
$transition-total: $transition-duration+$transition-delay;

.dropdown-menu__triangle {
  position: absolute;
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
  bottom: calc(100% - 26px);
  left: calc(50%);
  transform: translate(-50%, 0);
  z-index: 5;
  filter: drop-shadow(0px 17px 50px rgba(0, 0, 0, 0.19)) drop-shadow(0px 12px 15px rgba(0, 0, 0, 0.24));


  &.dropdown-menu__triangle-caracteristicas {
    left: 186px;

    &.es {
      left: 186px;
    }

    &.en {
      left: 164px;
    }

    &.ca {
      left: 193px;
    }
  }

  &.dropdown-menu__triangle-nosotros {
    left: 164px;

    &.es {
      left: 164px;
    }

    &.en {
      left: 156px;
    }

    &.ca {
      left: 166px;
    }
  }

  &.dropdown-menu__triangle-soporte {
    left: 162px;

    &.es {
      left: 162px;
    }

    &.en {
      left: 162px;
    }

    &.ca {
      left: 158px;
    }
  }

  &.dropdown-menu__triangle-language {
    left: 100px;
  }

}

.dropdown-menu {
  position: absolute;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, 0);
  padding-top: 25px;

  .dropdown-menu__contents {
    width: 260px;
    border-radius: 5px;
    background-color: #FFFFFF;


    @include shadow-large;
  }

  .dropdown-menu__item {
    height: 82px;
    width: 100%;
    background-color: #FFFFFF;

    display: flex;
    flex-direction: row;
    align-items: center;

    transition: $transition-duration;

    &:hover {
      background-color: #EDF2F6;
    }

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-radius: 0 0 5px 5px;
    }

    $image-padding-left: 16px;

    .dropdown-menu__image {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 63px - $image-padding-left;
      padding-left: $image-padding-left;
    }

    .dropdown-menu__text {

      .dropdown-menu__title {
        text-align: left;
        font-family: "Maison Neue Demi";
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0;
        color: #42A5F5;
      }

      .dropdown-menu__detail {
        text-align: left;
        font-family: "Maison Neue Book";
        font-size: 13px;
        line-height: 1em;
        letter-spacing: 0;
        color: #324659;
        opacity: 0.75;
        margin-top: 8px;
      }
    }
  }

  .dropdown-menu__line {
    background-color: #8D9EA6;
    height: 1px;
    width: 100%;
    opacity: 0.25;
  }

  &.dropdown-menu--language {
    .dropdown-menu__contents {
      width: 150px;
    }

    .dropdown-menu__item {
      height: 41px;
    }

  }
}


// .nav-row__nav-item.active:not(.deactive) {
//   .dropdown-menu {
//     pointer-events: auto;
//     visibility: visible;
//     opacity: 1;
//     top: 100%;

//     transition-delay: 0s;
//   }

// }
//

.nav-row__nav-item {
  .dropdown-menu {
    // initial phase
    top: calc(100% + 10px);
    pointer-events: none;
    opacity: 0;

    transition-property: top, opacity;
    transition-duration: 0.2s;
    transition-delay: 0.1s;
  }
}

.nav-row__nav-item.active:not(.deactive) {
  .dropdown-menu {
    top: 100%;
    pointer-events: auto;
    opacity: 1 !important;

    // Don't have a transition-delay when it's not active.
    transition-delay: 0s;
  }
}

.image-ventajas-dropdown {
  height: 34px;
  width: 33px;
}

.image-funcionalidad-dropdown {
  height: 34px;
  width: 33px;
}

.image-infraestructura-dropdown {
  height: 34px;
  width: 33px;
}

.image-api-dropdown {
  height: 28px;
  width: 32px;
}

.image-imagenes-dropdown {
  height: 34px;
  width: 33px;
}

.image-nosotros-dropdown {
  width: 38px;
}

.image-contacto-dropdown {
  height: 27px;
  width: 33px;
}

.image-blog-dropdown {
  height: 20px;
  width: 38px;
}

.image-soporte-clouding-dropdown {
  width: 33px;
}

.image-cloud-pro-dropdown {
  width: 38px;
}

.image-base-conocimiento-dropdown {
  width: 33px;
}

.image-language-dropdown {
  width: 21px;
}
