.register-section {

  &__section-divider {
    min-width: 1130px + 21px + 21px;
    background: transparent linear-gradient(270deg, #0B4182 0%, #1E88E5 53%, #40BAF5 100%) 0% 0% no-repeat;

    @include breakpoint(medium down) {
      background: linear-gradient(-10deg, #50CEFB 0%, #1E88E5 40%, #1565C0 100%);
      min-width: unset;
    }
  }

  &__content-container {
    width: 990px;
    margin: auto;
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;

    @include breakpoint(medium down) {
      flex-direction: column;
      width: initial;

      margin-top: 16px;
    }
  }

  &__email-input {
    width: 310px;
    margin: 10px 0;
    @include shadow-small;

    z-index: 3;

    @include breakpoint(medium down) {
      width: initial;
    }
  }


  &__password-input {
    width: 310px;
    margin: 10px 0;
    @include shadow-small;

    z-index: 2;

    @include breakpoint(medium down) {
      width: initial;
    }
  }

  &__button-container {
    @include breakpoint(medium down) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__button {
    width: 276px;
    margin: auto 0;
    margin-top: 10px;

    @include breakpoint(medium down) {
      width: 100%;
    }
  }

  &__accept-conditions-checkbox {
    margin-top: 20px;
    margin-left: 17px;
    margin-right: 19px;
  }

  &__accept-data-protection-checkbox {
    margin-top: 20px;
    margin-left: 17px;
    margin-right: 19px;
  }

}
